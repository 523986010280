import { AbilityBuilder, createMongoAbility, MongoAbility } from '@casl/ability'
import { ActionsAbility, SubjectAbility } from 'shared/types/ability'

import { normalizeMenuKey } from '@/shared/utils/permission'

type AppAbility = MongoAbility<[ActionsAbility, SubjectAbility]>

const ability = createMongoAbility()
const { can: builderCan, rules, cannot } = new AbilityBuilder<AppAbility>(createMongoAbility)

const can = (action: ActionsAbility, subject: SubjectAbility) =>
  builderCan(action, normalizeMenuKey(subject) as SubjectAbility)

const hasPermission = (action: string, subject: string) =>
  ability.can(action, normalizeMenuKey(subject))

export { ability, can, rules, cannot, hasPermission }

/**
 * @see https://casl.js.org/v6/en/guide/subject-type-detection#use-classes-as-subject-types
 */
