import { ActionsAbility, SubjectAbility } from 'shared/types/ability'

import { ability, can, rules } from '@/core/ability'
import { Menu } from '@/types'

export const normalizeMenuKey = (key: string = ''): string => {
  if (!key) return ''
  return key
    .replaceAll(' ', '')
    .replaceAll('-', '')
    .replaceAll('_', '')
    .replaceAll('/', '')
    .trim()
    .toLowerCase()
}

export const handleMenuAccess = (menus: Menu[]) => {
  menus.forEach((menu: Menu) => {
    grantMenuAccess(menu)
  })
  ability.update([...ability.rules, ...rules])
}

export const grantMenuAccess = (menu: Menu) => {
  const permissions = []

  if (menu.isVisible) {
    permissions.push(ActionsAbility.VISIBLE)
  }
  if (menu.isPrintable) {
    permissions.push(ActionsAbility.PRINT)
  }
  if (menu.isRetrieval) {
    permissions.push(ActionsAbility.RETRIEVE)
  }
  if (menu.isEditable) {
    permissions.push(ActionsAbility.UPDATE)
  }
  if (menu.isDeletable) {
    permissions.push(ActionsAbility.DELETE)
  }
  if (menu.isAdmin) {
    permissions.push(ActionsAbility.ADMIN)
  }

  permissions.forEach((permission: ActionsAbility) => {
    can(permission, menu.key as SubjectAbility)
  })

  if (menu.children.length) {
    menu.children.forEach((child: Menu) => {
      grantMenuAccess(child)
    })
  }
}
